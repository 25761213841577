import React from "react"
import Layout from "../components/layout"
import "../styles/about.css"

const AboutPage = () => {
  return (
    <Layout pageTitle="About QSoft">
      <h1 className="text-center">Jasa Pembuatan Software Custom</h1>

      <p className="text-lg mt-8 mb-8">
        <strong>QSoft</strong> adalah <strong>software developer di Surabaya</strong>,
        Spesialis <strong>jasa software custom</strong> (tailor made software),
        terutama untuk Supermarket dan Departemen Store yang menginginkan sistem Offline,
        serta Pabrikan yang menginginkan perhitungan HPP Produksi Akurat
      </p>
      <p className="text-center text-lg border-t-2 border-b-2 border-black mt-8 mb-8 p-4">
        Program custom yang telah kami buat diantaranya :
      </p>
      <ul className="points mt-8 mb-8 p-2 text-lg">
        <li>Program supermarket</li>
        <li>Program dept store</li>
        <li>Program distributor</li>
        <li>Program kasir / POS (Point of Sales)</li>
        <li>Program toko roti</li>
        <li>Program rumah makan</li>
        <li>Program akuntansi</li>
        <li>Program manufaktur</li>
        <li>Program jual-beli-stok</li>
        <li>Program billing</li>
        <li>Program akademis</li>
        <li>dll</li>
      </ul>
      
    </Layout>
  )
}

export default AboutPage