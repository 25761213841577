import React, { ReactNode } from 'react'
import Navbar from './navbar'


const Layout = ({ pageTitle, children }: {
  pageTitle: string,
  children: ReactNode,
}) => {
  return (
    <main>
      <title>{pageTitle}</title>
      <Navbar/>      
      <div className="w-full min-h-screen bg-blue-100">
        <div className="h-16"></div>
        <div className="container mx-auto p-4 ">
          {children}
        </div>
      </div>
    </main>
  )
}

export default Layout